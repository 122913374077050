import { utcToZonedTime, format, zonedTimeToUtc } from "date-fns-tz";
import moment from "moment";
import "moment-timezone";
import momentTz from "moment-timezone";

// Currently we are using cst - central standard time
// const timeZone = "America/Regina";
const defaultFormat = "MM/dd/yyyy, hh:mm a z";
const timestampFormat = "MM/DD/yyyy, hh:mm A z";

/**
 * This to get the timestamp
 * @params  first -  Date Object | ISO string
 * @params  second - optional
 *
 **/
const getTimeZoneTimeStamp = (timeZone: any, date: any, options?: any) => {
  // console.log(timeZone, date, options);

  return utcToZonedTime(date, timeZone, options).valueOf();
};

/**
 * This to get the user readble format to show in component
 * @params first-  use getTimeZoneTimeStamp()
 * @params second - yyyy-MM-dd HH:mm:ss zzz example
 **/
const getFormatedDate = (
  timeZone: any,
  formatedDate: number,
  formatString?: string
) => {
  // console.log(timeZone, formatedDate, formatString);
  let utcTime = zonedTimeToUtc(formatedDate, timeZone);
  let timezoneTime = moment(utcTime)
    ?.tz(timeZone)
    ?.format(formatString || timestampFormat);
  return timezoneTime;
  // return format(formatedDate, formatString || defaultFormat, {
  //   timeZone: timeZone,
  // });
};

//function to convert timestamp into desired timezone and format it as needed
/**
 *
 * @param date timestamp
 * @param plantTZ timezone
 * @returns
 */
const formatDateStr = (date: string, plantTZ: string): string => {
  const formattedDate = momentTz
    .utc(date)
    .tz(plantTZ)
    .format("MM/DD/YYYY hh:mm A");
  const timezoneAbbr = momentTz.tz(plantTZ).zoneAbbr();
  return `${formattedDate} ${timezoneAbbr}`;
};

//function to append time abbreviation after timestamp
export const appendTimezone = (date: string, plantTZ: string): string => {
  const formattedDate = moment(date).format("MM/DD/YYYY hh:mm A");
  const timezoneAbbr = momentTz.tz(plantTZ).zoneAbbr();

  return `${formattedDate} ${timezoneAbbr}`;
};

//function to append time abbreviation after timestamp
export const appendTimezoneWithoutConversion = (
  date: string,
  plantTZ: string
): string => {
  const formattedDate = moment.utc(date).format("MM/DD/YYYY hh:mm A");
  const timezoneAbbr = momentTz.tz(plantTZ).zoneAbbr();

  return `${formattedDate} ${timezoneAbbr}`;
};

// function to convert timestamp in a specific timezone to gmt
/**
 *
 * @param date - date string
 * @param fromTimeZone timezone of input date
 * @param dateType optional parameter to handle end dates
 * @param outputFormat format of converted output date
 * @returns
 */
const convertTimeZone = (
  date: string,
  fromTimeZone: string,
  dateType: string = "start",
  outputFormat = "YYYY-MM-DDTHH:mm:ss.000"
): string => {
  let onlyDate =
    dateType === "end"
      ? moment.utc(date).add(1, "day").format("YYYY-MM-DDT00:00:00")
      : moment.utc(date).format("YYYY-MM-DDT00:00:00");

  const from = moment.tz(onlyDate, fromTimeZone);

  return from.tz("UTC").format(outputFormat);
};

//function to return time difference between two timestamp strings in specific format
/**
 *
 * @param start_date - timstamp string. example:"2024-04-04 03:25:10.893"
 * @param end_date - timstamp string. example:"2024-04-04 05:00:06.553"
 * @returns duration- "01h 34m 55s"
 */
const timeDuration = (start_date: string, end_date: string) => {
  const start = new Date(start_date);
  const end = new Date(end_date);
  const duration = moment.duration(moment(end).diff(moment(start)));

  const h = Math.floor(duration.asHours()).toString().padStart(2, "0");
  const m = (Math.floor(duration.asMinutes()) % 60).toString().padStart(2, "0");
  const s = (Math.floor(duration.asSeconds()) % 60).toString().padStart(2, "0");

  return `${h}h ${m}m ${s}s`;
};

const convertFromPstToPlantTimezone = (
  timeString: any,
  timeZone: any,
  format: any
) => {
  return momentTz
    .tz(
      moment.utc(timeString).format("YYYY-MM-DDTHH:mm:ss.000"),
      "America/Los_Angeles"
    )
    .tz(timeZone)
    .format(format);
};

const dateFormatterForXaxis = (
  timeString: any,
  plant: any,
  difference: any
) => {
  if (
    moment(timeString).isValid() &&
    plant &&
    plant?.plant_timezone !== null &&
    plant?.plant_timezone !== undefined
  ) {
    if (difference <= 1) {
      return convertFromPstToPlantTimezone(
        timeString,
        plant?.plant_timezone,
        "hh:mmA"
      );
    } else if (difference < 365) {
      return convertFromPstToPlantTimezone(
        timeString,
        plant?.plant_timezone,
        "MM/DD hh:mma"
      );
    } else {
      return convertFromPstToPlantTimezone(
        timeString,
        plant?.plant_timezone,
        "M/DD/YY"
      );
    }
  } else {
    return timeString;
  }
};

const convertTimezoneCustom = (
  timestamp: any,
  sourceTimezone: any,
  targetTimezone: any
) => {
  // Parse the input timestamp in the source timezone
  const sourceTime = momentTz.tz(timestamp, sourceTimezone);

  // Convert the time to the target timezone
  const targetTime = sourceTime.tz(targetTimezone);

  // Return the converted time in the desired format
  return targetTime.format("YYYY-MM-DDTHH:mm:ss.SSS");
};

export {
  getTimeZoneTimeStamp,
  getFormatedDate,
  formatDateStr,
  convertTimeZone,
  timeDuration,
  convertFromPstToPlantTimezone,
  convertTimezoneCustom,
  dateFormatterForXaxis,
};
